import React, { useEffect, useState } from "react"

import axios from "axios"
import { useIntl } from "gatsby-plugin-intl"
import ContentContainer from "../../templates/ContentContainer"

import numberWithCommas from "../../utils/numberWithCommas"
import { URLProvider } from "../../utils/URLProvider"

enum CurrencyDisplayerType {
    marketCap = "marketCap",
    marketPrice = "marketPrice",
    marketVolume = "marketVolume",
}

enum Currencies {
    BTC = "BTC",
    USD = "USD",
    EUR = "EUR",
    RMB = "RMB",
}

type CurrencyDisplayer = {
    currency: Currencies
    type: CurrencyDisplayerType
    value: { marketPrice: string; marketVolume?: string; marketCap?: string }
}

const CurrencyDisplayer = ({ currency, type, value }: CurrencyDisplayer) => {
    return (
        <div className="text-white">
            <div className="text-[22px] font-bold">{value[type]}</div>
            <div className="text-lg">{currency}</div>
        </div>
    )
}

const CurrencyColumn: React.FC<{ title: string }> = ({ title, children }) => {
    return (
        <>
            <div className="text-horizen-content-grey text-lg">{title}</div>
            <div className="border-b my-5" />
            {children}
        </>
    )
}

const ZenPrices = () => {
    const [values, setValues] = useState({
        USD: {
            marketPrice: "-",
            marketCap: "-",
            marketVolume: "-",
        },
        RMB: {
            marketPrice: "-",
        },
        EUR: {
            marketPrice: "-",
        },
        BTC: {
            marketPrice: "-",
        },
    })

    const { formatMessage } = useIntl()

    useEffect(() => {
        const getZenMarketInfo = async () => {
            const data = await axios
                .get(URLProvider.URL_COINGECKO_API)
                .then((res) => {
                    return res.data
                })

            const marketVolume = numberWithCommas(
                Math.ceil(data.market_data.total_volume.usd)
            )

            const marketCap = numberWithCommas(
                Math.ceil(data.market_data.market_cap.usd)
            )

            const marketPrices = {
                USD: data.market_data.current_price.usd.toFixed(2),
                EUR: data.market_data.current_price.eur.toFixed(2),
                BTC: data.market_data.current_price.btc.toFixed(6),
                RMB: data.market_data.current_price.cny.toFixed(2),
            }

            setValues((prevState) => ({
                ...prevState,
                USD: {
                    marketPrice: marketPrices.USD,
                    marketCap,
                    marketVolume,
                },
                EUR: {
                    marketPrice: marketPrices.EUR,
                },
                BTC: {
                    marketPrice: marketPrices.BTC,
                },
                RMB: {
                    marketPrice: marketPrices.RMB,
                },
            }))
        }

        getZenMarketInfo()
    }, [])

    return (
        <ContentContainer>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 text-center">
                <div className="col-span-2">
                    <CurrencyColumn
                        title={formatMessage({
                            id: "exchanges.prices.zenPrice",
                        })}
                    >
                        <div className="grid grid-cols-4">
                            <CurrencyDisplayer
                                currency={Currencies.BTC}
                                type={CurrencyDisplayerType.marketPrice}
                                value={values.BTC}
                            />
                            <CurrencyDisplayer
                                currency={Currencies.USD}
                                type={CurrencyDisplayerType.marketPrice}
                                value={values.USD}
                            />
                            <CurrencyDisplayer
                                currency={Currencies.EUR}
                                type={CurrencyDisplayerType.marketPrice}
                                value={values.EUR}
                            />
                            <CurrencyDisplayer
                                currency={Currencies.RMB}
                                type={CurrencyDisplayerType.marketPrice}
                                value={values.RMB}
                            />
                        </div>
                    </CurrencyColumn>
                </div>
                <div>
                    <CurrencyColumn
                        title={formatMessage({
                            id: "exchanges.prices.marketCap",
                        })}
                    >
                        <CurrencyDisplayer
                            currency={Currencies.USD}
                            type={CurrencyDisplayerType.marketCap}
                            value={values.USD}
                        />
                    </CurrencyColumn>
                </div>
                <div>
                    <CurrencyColumn
                        title={formatMessage({
                            id: "exchanges.prices.volume",
                        })}
                    >
                        <CurrencyDisplayer
                            currency={Currencies.USD}
                            type={CurrencyDisplayerType.marketVolume}
                            value={values.USD}
                        />
                    </CurrencyColumn>
                </div>
            </div>
            <div className="text-white text-sm mt-6">
                {formatMessage({ id: "exchanges.prices.metricsPoweredBy" })}{" "}
                <a href={URLProvider.URL_COINGECKO} className="text-links">
                    CoinGecko
                </a>
            </div>
        </ContentContainer>
    )
}

export default ZenPrices
