import React from "react"
import * as styles from "../styles/exchanges/exchanges.module.css"

import ExchangesHelmet from "../components/SEO/ExchangesHelmet"
import ExchangesList from "../sections/exchanges/ExchangesList"
import LatestUpdatesBackground from "../assets/LatestUpdatesBackground.png"
import Layout from "../templates/Layout"
import NewsletterForm from "../components/NewsletterForm"
import NormalPageHero from "../components/NormalPageHero"
import OTC from "../sections/exchanges/OTC"
import Resources from "../sections/shared/Resources"
import ZenPrices from "../sections/exchanges/ZenPrices"

const Exchanges = () => {
    return (
        <Layout>
            <ExchangesHelmet />
            <div className="bg-horizen-hp-bg">
                <div
                    className={`pt-[9rem] md:pt-[calc(4rem+150px)] ${styles.exchangesHero}`}
                >
                    <NormalPageHero
                        titleKey="exchanges.hero.heading"
                        subtitleKey="exchanges.hero.p1"
                    />
                </div>
                <ZenPrices />
                <div className="pt-7">
                    <ExchangesList />
                </div>
                <div className="pt-20">
                    <OTC />
                </div>
                <div
                    className="bg-contain"
                    style={{
                        backgroundImage: `url(${LatestUpdatesBackground})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                >
                    <div className="pt-16 lg:pt-32">
                        <NewsletterForm />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Exchanges
