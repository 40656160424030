import { Helmet } from "react-helmet"
import React from "react"

const ExchangesHelmet = () => {
    return (
        <Helmet>
            <title>ZEN Exchanges - Buy and Trade ZEN - Horizen</title>
            <meta
                property="og:title"
                content="ZEN Exchanges - Buy and Trade ZEN - Horizen"
            />
            <meta
                name="twitter:title"
                content="ZEN Exchanges - Buy and Trade ZEN - Horizen"
            />
            <meta name="theme-color" content="#041742" />
            <meta
                name="description"
                content="Horizen's native cryptocurrency, ZEN, is a mineable PoW coin that is available on all major exchanges, including Coinbase, BitMart, Binance, and more."
            />
            <meta
                property="og:description"
                content="Horizen's native cryptocurrency, ZEN, is a mineable PoW coin that is available on all major exchanges, including Coinbase, BitMart, Binance, and more."
            />
            <meta
                name="twitter:description"
                content="Horizen's native cryptocurrency, ZEN, is a mineable PoW coin that is available on all major exchanges, including Coinbase, BitMart, Binance, and more."
            />
            <meta
                property="og:image"
                content="https://www.horizen.io/assets/images/meta/exchanges.jpg"
            />
            <meta
                name="twitter:image"
                content="https://www.horizen.io/assets/images/meta/exchanges.jpg"
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="keywords"
                content="zen exchange, horizen exchanges, zencash exchanges, buy zen, trade zen, where to buy zen, purchase zen, zen cash exchange"
            />{" "}
            <link rel="canonical" href="https://www.horizen.io/exchanges/" />
            <meta
                property="og:url"
                content="https://www.horizen.io/exchanges/"
            />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Horizen" />
            <meta name="twitter:site" content="@horizenglobal" />
            <meta name="twitter:creator" content="@horizenglobal" />
        </Helmet>
    )
}

export default ExchangesHelmet
