import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import BoxWithLinearGradient from "../BoxWithLinearGradient"

type BoxLogoAndName = {
    itemKey: string
    logoPath: string
    name: string
}

const BoxLogoAndName = ({ itemKey, logoPath, name }: BoxLogoAndName) => {
    const { formatMessage } = useIntl()
    return (
        <BoxWithLinearGradient>
            <a
                className="flex items-center h-full relative"
                href={formatMessage({ id: `${itemKey}.link` })}
            >
                <img
                    src={`${logoPath}/${name}Logo.svg`}
                    alt={`${name} logo`}
                    className="ml-[10.75%] max-h-[60px] max-w-[60px] w-full"
                />
                <span className="block absolute text-right pr-[10.75%] lg:pr-0 lg:text-center w-full font-bold lg:text-xl xl:text-[22px]">
                    {formatMessage({ id: `${itemKey}.name` })}
                </span>
            </a>
        </BoxWithLinearGradient>
    )
}

export default BoxLogoAndName
