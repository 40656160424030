import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import BoxLogoAndName from "../../components/Exchanges/BoxLogoAndName"
import ContentContainer from "../../templates/ContentContainer"
import SectionHeading from "../../components/SectionHeading"

const OTC = () => {
    const { formatMessage } = useIntl()
    const otcKeys = formatMessage({ id: "exchanges.otc.keys" })
    const otcKeysDecoded = otcKeys.split(",")
    return (
        <ContentContainer>
            <div className="text-center">
                <SectionHeading>
                    {formatMessage({ id: "exchanges.otc.sectionHeading" })}
                </SectionHeading>
                <p className="text-lg text-horizen-content-grey pt-8">
                    {formatMessage({ id: "exchanges.otc.p1" })}
                </p>
            </div>
            <div className="grid md:grid-cols-3 auto-rows-[100px] lg:auto-rows-[143px] gap-5 pt-12">
                {otcKeysDecoded.map((otc) => {
                    return (
                        <>
                            <BoxLogoAndName
                                itemKey={`exchanges.otc.${otc}`}
                                logoPath="/Exchanges/OTCLogos"
                                name={otc}
                            />
                        </>
                    )
                })}
            </div>
        </ContentContainer>
    )
}

export default OTC
