import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import BoxLogoAndName from "../../components/Exchanges/BoxLogoAndName"
import ContentContainer from "../../templates/ContentContainer"

const ExchangesList = () => {
    const { formatMessage } = useIntl()
    const exchangesKeys = formatMessage({ id: "exchanges.list.keys" })
    const exchangesKeysDecoded = exchangesKeys.split(",")
    return (
        <ContentContainer>
            <div className="grid md:grid-cols-3 auto-rows-[100px] lg:auto-rows-[143px] gap-5">
                {exchangesKeysDecoded.map((exchangeKey) => {
                    return (
                        <>
                            <BoxLogoAndName
                                logoPath="/Exchanges/ExchangesLogos"
                                itemKey={`exchanges.list.keys.${exchangeKey}`}
                                name={exchangeKey}
                            />
                        </>
                    )
                })}
            </div>
        </ContentContainer>
    )
}

export default ExchangesList
